import $ from "jquery";

function updateLabel(e) {
  const $e = $(e);
  const $label = $e.siblings(".floating-label-text-input-group__label-wrapper");

  if($e.is(":focus") || $e.val().toString().length > 0) {
    $label.addClass("is-active")
  } else {
    $label.removeClass("is-active")
  }
}

$(function() {
  // Floating Input Labels
  $(".floating-label-text-input-group input")
    .on("focus", (e) => updateLabel(e.currentTarget))
    .on("blur", (e) => updateLabel(e.currentTarget))
    .each((i, e) => updateLabel(e))
});
